<template>
  <div class="container mt-5 mb-2">
    <div class="row">
      <div class="col-12 col-md-8">
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group id="lblName" label="Name" label-for="txtName">
                <b-form-input
                  class="form-control"
                  id="txtName"
                  name="txtName"
                  v-model="$v.form.name.$model"
                  :state="validateState('name')"
                  aria-describedby="txtName-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="txtName-live-feedback">
                  Name is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group id="lblEmail" label="Email" label-for="txtEmail">
                <b-form-input
                  class="form-control "
                  id="txtEmail"
                  name="txtEmail"
                  v-model="$v.form.email.$model"
                  :state="validateState('email')"
                  aria-describedby="txtEmail-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="txtEmail-live-feedback">
                  Email is required and a valid email address.
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <b-form-group id="lblPhone" label="Phone" label-for="txtPhone">
                <b-form-input
                  class="form-control "
                  id="txtPhone"
                  name="txtPhone"
                  v-model="form.phone"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-12 col-md-6">
              <b-form-group
                id="lblSubject"
                label="Subject"
                label-for="txtSubject"
              >
                <b-form-input
                  class="form-control "
                  id="txtSubject"
                  name="txtSubject"
                  v-model="$v.form.subject.$model"
                  :state="validateState('subject')"
                  aria-describedby="txtSubject-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="txtSubject-live-feedback">
                  Subject is required.
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <b-form-group
                id="lblMessage"
                label="Message"
                label-for="txtMessage"
              >
                <b-form-textarea
                  class="form-control "
                  id="txtMessage"
                  name="txtMessage"
                  v-model="form.message"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
          </div>
          <button class="btn btn-success">
            Submit
          </button>
        </b-form>
      </div>
      <div class="col-12 col-md-4 order-0 order-md-1">
        <h2 class="text-dark mb-4">Locate us</h2>
        <div class="d-block border-bottom py-4">
          <h4 class="t-700">Head Office</h4>
          <p class="text-muted mb-0">
            Dambulla road,<br />
            Galewela
          </p>
        </div>
        <div class="d-block border-bottom py-4">
          <h4 class="t-700">Branch</h4>
          <p class="text-muted mb-0">
            356/E/1 Welemulla,<br />
            Millawana
          </p>
        </div>
        <div class="d-block border-bottom py-4">
          <h4 class="t-700">Customer Complains</h4>
          <p class="text-muted mb-0">
            T : 0772679733 / 0717009733 <br />
            Email :
            <a href="mailto:support@fastlankacredit.com"
              >support@fastlankacredit.com</a
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { email, minLength, required } from "vuelidate/lib/validators";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/services/api.service";
export default {
  mixins: [validationMixin],
  name: "contact",
  data() {
    return {
      form: {
        email: null,
        name: null,
        subject: null,
        phone: null,
        message: null
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      name: {
        required
      },
      subject: {
        required,
        minLength: minLength(5)
      }
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Contact Us" }]);
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSubmit() {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const email = this.$v.form.email.$model;
      const name = this.$v.form.name.$model;
      const phone = this.$v.form.phone.$model;
      const subject = this.$v.form.subject.$model;
      const message = this.$v.form.message.$model;

      setTimeout(() => {
        ApiService.post("contact", {
          email,
          name,
          phone,
          subject,
          message
        }).then(() => this.$router.push({ name: "Home" }));
      }, 2000);
    }
  }
};
</script>
